import { Card, FlexBox, Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Title } from './title'

export interface Props {
  greeting: string
}

export const Fragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  return (
    <FlexBox>
      <Card style={{ width: '100%' }}>
        <Title title='wix-websites-fragments' />
        <Typography textAllCaps fontWeight='bold' mb={1} mr={1}>
          {props.greeting} from wix-websites-fragments fragment!
        </Typography>
      </Card>
    </FlexBox>
  )
}
